<template>
  <div>
    <div class="card-header">
      <StringFilter :isSearching="queryPending" @updateSearch="filterByString" />
    </div>
    <div class="card-header">
      <div class="row">
        <div class="col-12 mb-0">
          <SurveyCount :isSearching="queryPending" />

          <!-- filters -->
          <div class="mb-3 clearfix">
            <!-- date -->
            <div class="dropdown d-inline-block mr-4 mb-2 pt--2 filter-container date-filter">
              <h6 class="header-pretitle mt-3">Date</h6>
              <SurveyDateFilter @updateDateSettings="filterByDate" class="filter-box" />
            </div>

            <!-- company -->
            <div
              class="dropdown d-inline-block mr-4 mb-2 filter-container"
              v-if="activeCompany._id === 'ALL_COMPANIES'"
            >
              <h6 class="header-pretitle mt-3">Companies</h6>
              <CompanyFilter
                @update="filterByCompany"
                :wideMode="true"
                :companies="selectCompanies"
                :activeCompany="activeCompany"
                class="filter-box"
              />
            </div>

            <!-- location -->
            <div
              v-if="locations.length > 1"
              class="dropdown d-inline-block mr-4 mb-2 filter-container"
            >
              <h6 class="header-pretitle mt-3">Locations</h6>
              <GroupingFilter
                :nonGroupMembers="companyFilteredLocations"
                nonGroupMemberLabel="Locations"
                memberType="location"
                @update="groupingUpdate"
                :init="{}"
                :width="180"
                :companies="filteredCompanies"
                :key="
                  activeCompany._id +
                  filteredCompanies.join() +
                  companyFilteredLocations.map((l) => l._id).join()
                "
                class="filter-box"
              />
            </div>

            <!-- rating -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Rating</h6>
              <RatingFilter @updateRatings="filterByRating" class="filter-box" />
            </div>

            <!-- source -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Source</h6>
              <SourceFilter
                @updateSources="filterBySource"
                :multiCompany="true"
                class="filter-box"
              />
            </div>

            <!-- offer -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Offer</h6>
              <SingleSelectFilter
                :options="offerOptions"
                @updateFilters="filterOffer"
                class="filter-box"
              />
            </div>

            <!-- feedback -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Feedback</h6>
              <SingleSelectFilter
                :options="feedbackOptions"
                @updateFilters="filterFeedback"
                class="filter-box"
              />
            </div>

            <!-- responded -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Responded</h6>
              <SingleSelectFilter
                :options="responseOptions"
                @updateFilters="filterResponded"
                class="filter-box"
              />
            </div>

            <!-- anonymous -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Anonymous</h6>
              <SingleSelectFilter
                :options="anonymousOptions"
                @updateFilters="filterAnonymous"
                class="filter-box"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerStringFilter from '@/components/Modules/Customer/CustomerFilters/CustomerStringFilter'
import SurveyCount from './SurveyFiltersSection/SurveyCount'
import SurveyDateFilter from './SurveyFiltersSection/SurveyDateFilter'
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import SourceFilter from './SurveyFiltersSection/SourceFilter'
import SingleSelectFilter from './SurveyFiltersSection/SingleSelectFilter'
import CustomerRatingFilter from '@/components/Modules/Customer/CustomerFilters/CustomerRatingFilter'
import CompanyFilter from '@/components/Modules/Filters/CompanyFilter'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const SurveyModule = createNamespacedHelpers('survey')
const CompanyModule = createNamespacedHelpers('company')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'SurveyTableFilters',
  components: {
    StringFilter: CustomerStringFilter,
    SurveyCount,
    // SurveyLocationFilter,
    GroupingFilter,
    SurveyDateFilter,
    RatingFilter: CustomerRatingFilter,
    SourceFilter,
    SingleSelectFilter,
    CompanyFilter,
  },
  data: () => ({
    queryPending: false,
    offerOptions: ['All', 'Sent', 'Not Sent'],
    feedbackOptions: ['All', 'Has Feedback', 'No Feedback'],
    responseOptions: ['All', 'Responded', 'Unresponded'],
    anonymousOptions: ['All', 'Anonymous', 'Not Anonymous'],
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredCompanies: [],
  }),
  computed: {
    ...LocationModule.mapState({ locations: 'userActiveCompanyLocations' }),
    ...SurveyModule.mapState(['listFilters']),
    ...CompanyModule.mapGetters(['activeCompany', 'selectCompanies']),
    locationRefreshKey() {
      return this.activeCompany._id + this.locations.map((c) => c._id).join('')
    },
    companyFilteredLocations() {
      return this.locations.filter(
        (l) => !this.filteredCompanies?.length || this.filteredCompanies.includes(l.company)
      )
    },
  },
  methods: {
    ...SurveyModule.mapActions(['getSurveys', 'resetFilters']),
    ...SurveyModule.mapMutations(['setListFilters']),
    ...GroupModule.mapActions(['selectGroupLocationIds']),

    setQueryPending(isPending) {
      this.queryPending = isPending
      this.$emit('queryPending', isPending)
    },

    applyFilters(filters) {
      this.$emit('resetSkip')
      this.setListFilters(filters)
      if (!this.queryPending) {
        this.$nextTick(async () => {
          try {
            await this.getSurveys({})
          } finally {
            this.setQueryPending(false)
          }
        })
      }
      this.setQueryPending(true)
    },

    filterByString(search) {
      this.applyFilters({ ...this.listFilters, search })
    },

    filterByDate(dateSettings = {}) {
      this.applyFilters({ ...this.listFilters, dateSettings })
    },

    filterByCompany(companies) {
      this.filteredCompanies = companies
      this.applyFilters({
        ...this.listFilters,
        companyIds: companies,
      })
    },

    async groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null

      let locationsToFilter = [
        ...new Set([...(this.filteredLocations || []), ...(this.filteredGroupMembers || [])]),
      ]
      this.applyFilters({
        ...this.listFilters,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
      })
    },

    filterByRating(ratings) {
      this.applyFilters({
        ...this.listFilters,
        surveyRatings: ratings.map((rating) => rating.score),
      })
    },

    filterBySource(sources) {
      this.applyFilters({ ...this.listFilters, sources })
    },

    filterOffer(selection) {
      this.applyFilters({ ...this.listFilters, hasSentOffer: selection })
    },

    filterFeedback(selection) {
      this.applyFilters({ ...this.listFilters, hasFeedback: selection })
    },

    filterResponded(selection) {
      this.applyFilters({ ...this.listFilters, responded: selection })
    },

    filterAnonymous(selection) {
      this.applyFilters({ ...this.listFilters, anonymous: selection })
    },
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        let filters = { ...this.listFilters }
        delete filters.companyIds
        delete filters.locations
        this.applyFilters(filters)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.date-filter {
  top: 0px;
}
.filter-container {
  height: 80px;
  float: left;
  padding: 0px !important;
}
.filter-box {
  min-width: 11rem !important;
}
</style>
